<template>
  <div class="footer-space"></div>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-xs-12 text-center"> 
          <h3>Solano Stories</h3>
        </div>
        <div class="col-xs-12">
          <div class="sub-footer"> <span class="copyright">© 2022 Rex Solano | All Rights Reserved</span>
            <ul>
              <li>Made in Hawaii 🌈</li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>