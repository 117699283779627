<template>
  <div class="navigation" :class="{ 'show-me': menuOpen }" role="navigation">
    <div class="table">
      <div class="inner">
        <nav>
          <ul>
            <li :class="{ 'show-me': menuOpen }"><router-link :to="{ name: 'Home' }">HOME</router-link></li>
          </ul>
        </nav>
        <span class="copyright">© 2021 Rex Solano | Solano Stories</span> </div>
    </div>
  </div>

  <nav class="navbar navbar-default show" role="navigation">
    <div class="container">
      <div class="row">
        <div class="col-xs-12"> <router-link :to="{ name: 'Home'}"><img src="/images/logo.png" alt="Image" class="logo"></router-link>
            <!-- <div @click="menuOpen = !menuOpen" class="hamburger-menu" :class="{'menu-open': menuOpen}" :style="{ '--color': light ? '#fff' : '#bbb'}"> 
                <span></span> 
                <span></span> 
                <span></span> 
                <span></span> 
            </div>   -->
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from 'vue'
export default {
    props: [ 'light' ],
    setup(props) {
        const menuOpen = ref(false)
        console.log(props.light)
        return { menuOpen }
    }
}
</script>

<style>

</style>