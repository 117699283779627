<template>
  <Nav />
  <main>
  <section class="news">
  	<div class="container">
    	<div class="row">
        	<div v-for="post in posts" :key="post.sys.id" class="col-md-4">
            	<div class="news-post-list">
                    <figcaption>
                    <small>{{post.updatedDate }}</small>
                    	<h3><router-link :to="{ name: 'InsightsDetail', params: { slug: post.fields.slug } }">{{ post.fields.title }}</router-link></h3>
                        <p>{{ post.snippet }}</p>
                    </figcaption>
                </div>
            </div>
        </div>
    </div>
  </section>
</main>
  <Footer />
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import useContentful from '@/composables/useContentful'

export default {
    components: { Nav , Footer },
    setup() {
        const { posts, getPostsError, getPosts } = useContentful()
        getPosts(100)
        return { posts }
    }
}
</script>

<style scoped>
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
}
.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
</style>