const contentful = require("contentful");
const client = contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: "9z8avqs1bzfl",
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: "TAAFf3sx4su5ZMYogx2iss9BRbU6-4Btow-JbILWvyU"
});

export { client }

