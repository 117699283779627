import { ref } from 'vue'
import { client } from '@/contentful/config'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const posts = ref([])
const post = ref({})
const getPostsError = ref()
const getPostError = ref()


const getPosts = async (limit=3) => {
    try {
        const res = await client.getEntries({'content_type': 'blogPost', 'limit': limit, order:'-sys.createdAt'})
        posts.value = res.items.map(post => {
            post['updatedDate'] = (new Date(post.sys.updatedAt)).toLocaleDateString()
            const story = post.fields.story.content
            story.forEach((node) => {
                if(post.snippet){
                    return
                }
                if(node.nodeType == 'paragraph') {
                    const snip = node.content[0].value
                    if(snip && !snip.startsWith('\n')) {
                        post['snippet'] = snip.substring(0, 255) + '...'
                        return post
                    }
                }
            })
            return post
        })
    } catch(err) {
        console.log(err)
    }
}

const getPost = async (slug) => {
    try {
        const res = await client.getEntries({
            'content_type': 'blogPost', 
            'fields.slug[in]': slug, 
        })
        if(res.items) {
            post.value = res.items[0]
            var updatedDate = post.value.sys.updatedAt 
            post.value['updatedDate'] = (new Date(updatedDate)).toLocaleDateString()
            const htmlStory = documentToHtmlString(post.value.fields.story)
            post.value['htmlstory'] = htmlStory
        } else {
            throw 'post not found'
        }
    } catch(err) {
        console.log(err)
        getPostError.value = err
    }
}

const useContentful = () => {
    return { posts, getPostsError, getPosts, post, getPostError, getPost }
}

export default useContentful