<template>
  <Nav />
 <main>
  <section class="news">
  	<div class="container">
    	<div class="row">
        	<div class="col-xs-12">
            	<div v-if="post.fields" class="news-post">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center text-center">
                    <figcaption>
                      <small>{{ post.updatedDate }}</small>
                      <h1>{{ post.fields.title }}</h1>
                    </figcaption>
                  </div>         
                </div>
                	
                    <figcaption>
                      <div v-html="post.htmlstory"></div>
                    </figcaption>
                </div>
                <!-- end news-post -->
            </div>
            <!-- end col-12 -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- end news -->
</main>
  <Footer />
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import { useRoute } from 'vue-router'
import useContentful from '@/composables/useContentful'

export default {
    components: { Nav , Footer },
    setup() {
        const route = useRoute()
        const { post, getPostError, getPost } = useContentful()
        getPost(route.params.slug)
        return { post }
    }
}
</script>

<style>

</style>