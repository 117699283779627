<template>


<div class="transition-overlay"></div>



  <!-- <Nav :light="true" /> -->

  <section class="slider goes-up">
    <div class="video-bg">
      <video src="/videos/hawaii.mp4" muted loop autoplay playsinline></video>
      <div class="table">
        <div class="inner">
            <h2>Rex Solano</h2>
              <h5>SOLANO STORIES</h5>
              <!-- <img src="/ico/kmk.png" alt="Image"> -->
          </div>

      </div>

    </div>
  </section>

  <main>






    <section class="services-list">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="title-box">
              <h2 class="wow fadeInUp" data-wow-delay="0.05s">latest stories</h2>
              <span class="shape wow fadeInUp" data-wow-delay="0.15s">O</span> </div>
          </div>


          <div v-for="post in posts" :key="post.sys.id" class="col-md-4 col-sm-12">
            <router-link :to="{ name: 'InsightsDetail', params: {slug: post.fields.slug }}"><h4>{{ post.fields.title }}</h4></router-link>
            <p>{{ post.snippet }}</p>
          </div>

        </div>

        <div class="row d-flex justify-content-center">
          <div class="col" style="text-align:center">
              <router-link :to="{ name: 'Insights'}"><button class="btn btm-primary">See More</button></router-link>
          </div>
          
        </div>
      </div>
    </section>
  </main>

  <Footer />

</template>

<script>
// @ is an alias to /src
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import useContentful from '@/composables/useContentful'

export default {
  name: 'Home',
  components: { Nav , Footer },
  setup() {
    const { posts, getPostsError, getPosts} = useContentful()
    getPosts()

    return { posts }
  }

}
</script>
